import React from 'react';
import './App.css';
import AppRouter from './router';
import './css/index.css'


function App() {

  return (
    <AppRouter />
  )
}

export default App;
